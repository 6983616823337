import React from "react"

const redirect = () => {
  window.open("https://www.linkedin.com/company/civictechto/")
}

const LinkedinMobile = (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    x="151"
    y="514"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={redirect}
  >
    <path
      d="M24.5 0C10.9689 0 0 10.9689 0 24.5C0 38.0311 10.9689 49 24.5 49C38.0311 49 49 38.0311 49 24.5C49 10.9689 38.0311 0 24.5 0ZM18.5026 34.6547H13.5414V18.6889H18.5026V34.6547ZM15.9914 16.7289C14.4244 16.7289 13.4112 15.6187 13.4112 14.2457C13.4112 12.8446 14.455 11.7677 16.0552 11.7677C17.6553 11.7677 18.6353 12.8446 18.6659 14.2457C18.6659 15.6187 17.6553 16.7289 15.9914 16.7289ZM36.6224 34.6547H31.6611V25.8067C31.6611 23.7471 30.9415 22.3486 29.1473 22.3486C27.7769 22.3486 26.9628 23.2954 26.6029 24.2065C26.4702 24.5306 26.437 24.99 26.437 25.4468V34.6522H21.4732V23.7803C21.4732 21.7871 21.4094 20.1206 21.3431 18.6864H25.6535L25.8807 20.9041H25.9802C26.6335 19.8629 28.2337 18.3265 30.9108 18.3265C34.1749 18.3265 36.6224 20.5136 36.6224 25.2146V34.6547Z"
      fill="#4529AE"
    />
  </svg>
)

export default LinkedinMobile

import React from "react"

const redirect = () => {
  window.open("https://www.instagram.com/civictechto")
}

const InstagramMobile = (
  <svg
    width="50"
    height="50"
    x="20"
    y="514"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={redirect}
  >
    <path
      d="M29.5453 24.9999C29.5453 27.5103 27.5102 29.5453 24.9998 29.5453C22.4894 29.5453 20.4543 27.5103 20.4543 24.9999C20.4543 22.4895 22.4894 20.4544 24.9998 20.4544C27.5102 20.4544 29.5453 22.4895 29.5453 24.9999Z"
      fill="#4529AE"
    />
    <path
      d="M35.8057 16.8251C35.5836 16.2232 35.2293 15.6785 34.7689 15.2313C34.3217 14.7709 33.7773 14.4166 33.1751 14.1945C32.6867 14.0048 31.953 13.779 30.6015 13.7175C29.1396 13.6508 28.7013 13.6364 25.0003 13.6364C21.2989 13.6364 20.8606 13.6504 19.399 13.7171C18.0476 13.779 17.3135 14.0048 16.8255 14.1945C16.2233 14.4166 15.6785 14.7709 15.2317 15.2313C14.7713 15.6785 14.417 16.2229 14.1945 16.8251C14.0048 17.3135 13.779 18.0476 13.7175 19.399C13.6508 20.8606 13.6365 21.2989 13.6365 25.0003C13.6365 28.7013 13.6508 29.1396 13.7175 30.6015C13.779 31.9529 14.0048 32.6866 14.1945 33.1751C14.417 33.7773 14.7709 34.3217 15.2314 34.7688C15.6785 35.2292 16.2229 35.5836 16.8251 35.8057C17.3135 35.9958 18.0476 36.2215 19.399 36.2831C20.8606 36.3497 21.2985 36.3637 24.9999 36.3637C28.7017 36.3637 29.14 36.3497 30.6012 36.2831C31.9526 36.2215 32.6867 35.9958 33.1751 35.8057C34.384 35.3394 35.3394 34.3839 35.8057 33.1751C35.9954 32.6866 36.2212 31.9529 36.2831 30.6015C36.3498 29.1396 36.3637 28.7013 36.3637 25.0003C36.3637 21.2989 36.3498 20.8606 36.2831 19.399C36.2216 18.0476 35.9958 17.3135 35.8057 16.8251ZM25.0003 32.1176C21.0691 32.1176 17.8823 28.9311 17.8823 24.9999C17.8823 21.0687 21.0691 17.8822 25.0003 17.8822C28.9311 17.8822 32.118 21.0687 32.118 24.9999C32.118 28.9311 28.9311 32.1176 25.0003 32.1176ZM32.3994 19.2642C31.4808 19.2642 30.736 18.5194 30.736 17.6008C30.736 16.6822 31.4808 15.9374 32.3994 15.9374C33.318 15.9374 34.0628 16.6822 34.0628 17.6008C34.0624 18.5194 33.318 19.2642 32.3994 19.2642Z"
      fill="#4529AE"
    />
    <path
      d="M25 0C11.195 0 0 11.195 0 25C0 38.805 11.195 50 25 50C38.805 50 50 38.805 50 25C50 11.195 38.805 0 25 0ZM39.2689 30.9185C39.1994 32.4467 38.9565 33.49 38.6017 34.4032C37.8559 36.3316 36.3316 37.8559 34.4032 38.6017C33.4904 38.9565 32.4467 39.1991 30.9189 39.2689C29.388 39.3387 28.899 39.3555 25.0004 39.3555C21.1014 39.3555 20.6127 39.3387 19.0815 39.2689C17.5537 39.1991 16.51 38.9565 15.5972 38.6017C14.6389 38.2412 13.7714 37.6762 13.0543 36.9457C12.3241 36.2289 11.7592 35.3611 11.3987 34.4032C11.0439 33.4904 10.8009 32.4467 10.7315 30.9189C10.6609 29.3877 10.6445 28.8986 10.6445 25C10.6445 21.1014 10.6609 20.6123 10.7311 19.0815C10.8006 17.5533 11.0432 16.51 11.3979 15.5968C11.7584 14.6389 12.3238 13.7711 13.0543 13.0543C13.7711 12.3238 14.6389 11.7588 15.5968 11.3983C16.51 11.0435 17.5533 10.8009 19.0815 10.7311C20.6123 10.6613 21.1014 10.6445 25 10.6445C28.8986 10.6445 29.3877 10.6613 30.9185 10.7315C32.4467 10.8009 33.49 11.0435 34.4032 11.3979C35.3611 11.7584 36.2289 12.3238 36.9461 13.0543C37.6762 13.7714 38.2416 14.6389 38.6017 15.5968C38.9568 16.51 39.1994 17.5533 39.2693 19.0815C39.3391 20.6123 39.3555 21.1014 39.3555 25C39.3555 28.8986 39.3391 29.3877 39.2689 30.9185Z"
      fill="#4529AE"
    />
  </svg>
)

export default InstagramMobile

import React from "react"

const redirect = () => {
  window.open("https://twitter.com/CivicTechTO")
}

const TwitterMobile = (
  <svg
    width="49"
    height="49"
    x="86"
    y="515"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={redirect}
  >
    <path
      d="M24.5 0C10.9711 0 0 10.9711 0 24.5C0 38.0289 10.9711 49 24.5 49C38.0289 49 49 38.0289 49 24.5C49 10.9711 38.0289 0 24.5 0ZM35.6864 19.1025C35.6973 19.3436 35.7025 19.5859 35.7025 19.8292C35.7025 27.2601 30.0463 35.8289 19.7025 35.8292C16.5267 35.8292 13.5715 34.8984 11.0829 33.3032C11.5229 33.3552 11.9707 33.381 12.4242 33.381C15.059 33.381 17.4838 32.4822 19.4087 30.9738C16.9469 30.9282 14.8714 29.3024 14.1551 27.0679C14.4979 27.1337 14.8504 27.1696 15.2119 27.1696C15.7252 27.1696 16.2224 27.1004 16.695 26.9715C14.1218 26.4563 12.1835 24.1822 12.1835 21.4592C12.1835 21.4338 12.1835 21.411 12.1842 21.3874C12.942 21.8087 13.8085 22.0622 14.7312 22.0906C13.2212 21.0831 12.2287 19.3608 12.2287 17.4097C12.2287 16.3794 12.5072 15.4142 12.9902 14.5831C15.7634 17.9858 19.9077 20.2236 24.5815 20.4588C24.485 20.0468 24.4353 19.6176 24.4353 19.1765C24.4353 16.0721 26.9539 13.5536 30.0594 13.5536C31.677 13.5536 33.138 14.2373 34.1641 15.3304C35.4453 15.0777 36.6483 14.6097 37.7351 13.9656C37.3145 15.2781 36.4233 16.3794 35.2621 17.0759C36.3997 16.9398 37.4838 16.6381 38.4913 16.1903C37.7388 17.3182 36.7844 18.3088 35.6864 19.1025Z"
      fill="#4529AE"
    />
  </svg>
)

export default TwitterMobile
